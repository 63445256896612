<template>
  <ion-page>
    <ion-header class="dark-theme">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Scan Grid</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="dark-theme">
      <p>...TODO...</p>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton, 
  IonButtons, 
} from '@ionic/vue';

// import * as cv from '@/scanner/lib/opencv.js';
// const cv:any = require('@/scanner/lib/opencv.js')

export default  {
  name: 'PastGames',
  components: {
    IonHeader,
    IonToolbar,
    IonBackButton, 
    IonButtons,
    IonTitle,
    IonContent,
    IonPage
  },

  // created() {
    
  // },

}
</script>